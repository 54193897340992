<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterATSalesmanSalesman
                    style="width:100%;"
                    v-model:value="state.params.salesman"
                    v-model:useram_id="state.useram_id"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.toko"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.active"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_TSO])"
                        title="Upload Dokumen Toko">
                        <AButton
                            type="primary"
                            @click="btnUploadToko">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_TSO])"
                        title="Approve">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnActionApprove"
                            type="primary">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_TSO])"
                        title="Reject">
                        <AButton
                            :loading="state.isActionReject"
                            @click="btnActionReject"
                            type="danger">
                            <span class="fa fa-ban mr-2" aria-hidden="true"/> Reject
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_HELPDESK, ROLE_TIM_CRM])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Master-User-Salesman"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <!-- role TSO akan menampilkan centang untuk approve and reject -->
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                :row-selection="hasRoles([ROLE_TSO]) ? {
                    selectedRowKeys: state.selectedRowKeys,
                    onChange: onSelectChange,
                    getCheckboxProps: (record) => ({
                        disabled: !record.approval_action,
                    }),
                } : null"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK, ROLE_ASM, ROLE_SSM, ROLE_GM])"
                            title="List Distributor">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.active && record.approval_action"
                                @click="btnDistributorList(record)">
                                <i class="fa fa-calendar-check-o" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK, ROLE_ASM, ROLE_SSM, ROLE_GM])"
                            title="List Toko">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.active && record.approval_action"
                                @click="btnTokoList(record)">
                                <i class="fa fa-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.active && record.approval_action"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TSO, ROLE_TIM_CRM, ROLE_HELPDESK])"
                            title="Details">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <DistributorList
            v-if="visibleDistributorListModal"
            v-model:visible="visibleDistributorListModal"
            v-model:item="visibleDistributorListItemModal"/>

        <!-- detail -->
        <TokoList
            v-if="visibleTokoListModal"
            v-model:visible="visibleTokoListModal"
            v-model:item="visibleTokoListItemModal"/>

        <!-- upload -->
        <UploadTokoList
            v-if="visibleUploadTokoModal"
            v-model:visible="visibleUploadTokoModal"
            @success="fetchDataList"/>
            
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterATSalesmanSalesman from '@/components/filter/FilterATSalesmanSalesman'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import DistributorList from './DistributorList'
import TokoList from './TokoList'
import UploadTokoList from './UploadTokoList'
import localStorage from 'store'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_TSO,
    ROLE_TIM_CRM,
    ROLE_DISTRIBUTOR,
    ROLE_HELPDESK,
    ROLE_ASM,
    ROLE_SSM,
    ROLE_GM,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        DistributorList,
        TokoList,
        UploadTokoList,
        DownloadExcel,
        FilterATSalesmanSalesman,
        FilterToko,
        FilterDistributor,
        FilterRegional,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nomor KTP/NPWP',
                    dataIndex: 'nopeg',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'fullname',
                },
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
                {
                    title: 'Telepon',
                    dataIndex: 'phone',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributors',
                },
                {
                    title: 'Status Approval',
                    dataIndex: 'status_approval',
                },
                {
                    title: 'Approval Dari TSO',
                    dataIndex: 'tso_name',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/users-salesman',
                approve: '/api/users-salesman/distributor/request-approve',
                reject: '/api/users-salesman/distributor/request-reject',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isActionApprove: false,
            isActionReject: false,
            params: extractQueryParams({
                q: '',
                salesman: [],
                toko: [],
                region: [],
                distributor: [],
                active: null,
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Nonaktif',
                    value: 0,
                },
            ],
            useram_id: null,
            vendor_id: null,
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActionApprove = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    state.isActionApprove = true
                    
                    apiClient.post(state.endpoint.approve, {
                            id: state.selectedRows.map(item => item.id),
                        })
                        .then(({ data }) => {
                            if (data.status == 'success') {
                                message.success(data.message)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            state.isActionApprove = false
                        })
                },
                onCancel() { },
            })
        }

        const btnActionReject = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Reject',
                content: 'Apakah Anda ingin reject?',
                onOk() {
                    state.isActionReject = true
                    
                    apiClient.post(state.endpoint.reject, {
                            id: state.selectedRows.map(item => item.id),
                        })
                        .then(({ data }) => {
                            if (data.status == 'success') {
                                message.success(data.message)
                            }

                            state.selectedRowKeys = []
                            fetchDataList()
                        })
                        .finally(() => {
                            state.isActionReject = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle distributor list
        const visibleDistributorListModal = ref(false);
        const visibleDistributorListItemModal = ref(null);

        const btnDistributorList = (item = null) => {
            visibleDistributorListModal.value = true
            visibleDistributorListItemModal.value = item
        }

        // handle toko list
        const visibleTokoListModal = ref(false);
        const visibleTokoListItemModal = ref(null);

        const btnTokoList = (item = null) => {
            visibleTokoListModal.value = true
            visibleTokoListItemModal.value = item
        }

        // handle upload toko
        const visibleUploadTokoModal = ref(false);

        const btnUploadToko = () => {
            visibleUploadTokoModal.value = true
        }

        onMounted(() => {
            fetchDataList()

            // delete column only role admin bk and tim crm
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['status_approval', 'tso_name'], item.dataIndex)) {
                        return item
                    }
                })
            }

            if (hasRoles([ROLE_TSO])) {
                state.useram_id = user.value.id
            }

            if (hasRoles([ROLE_DISTRIBUTOR])) {
                state.vendor_id = user.value.vendor_id
            }
        })

        return {
            fetchDataList,
            handleTableChange,
            state,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            visibleDistributorListModal,
            visibleDistributorListItemModal,
            btnDistributorList,
            visibleTokoListModal,
            visibleTokoListItemModal,
            btnTokoList,
            user,
            queryParams,
            onSelectChange,
            btnActionApprove,
            btnActionReject,
            btnUploadToko,
            visibleUploadTokoModal,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_TSO,
            ROLE_TIM_CRM,
            ROLE_DISTRIBUTOR,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_SSM,
            ROLE_GM,
        }
    },
})
</script>
